#chat-zm.container-fluid {
  max-width: inherit !important;
}

/* --------CHATROOM---------- */
#chat-zm {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
#chat-zm .btn, button{
  cursor:pointer !important;
}
.main-section{
  border: 1px solid #dadadc;
  max-width:1600px;
}
.chatroom {
  background-color: #fff;

  .name {
    span, small {
      display: inline;
    }
  }
}
.chatroom .header{
  border-bottom:2px solid #eaeef1;
  padding:20px;
  height:90px;
  background-color:#fff;
  position:relative;
}
.chatroom .header .logo {
  width: 50px;
  height: 50px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 25px;
  padding-left: 13px;
  padding-top: 9px;
  background: #f1f1f4;
}
.chatroom .utilisateur {
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding-bottom: 15px;
  padding-left: 65px;
  background: #f7f7f7;

  .users-img {
    position: absolute;
    top: 17px;
    left: 15px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .fa-stack {
    height: 14px !important;
    width: 14px !important;
    border: 2px solid #fff;
    right: -2px;
    bottom: 5px;
  }
}
.h1, h1 {
  font-weight: bold;
}
#icons {
  position: absolute;
  right: 20px;
  top: 25px;
}
.icon-background {
  color: #eaeef1;
}
#icons .icon {
  color:#333;
}
#icons a:hover{
  opacity:0.75;
}
.nav-fill .nav-item {
  padding: 10px;
}
.nav-pills .nav-link {
  background: #f1f1f4;
  text-align: left;
  color: #333;
  position: relative;
}
.nav-pills .nav-link .mess {
  position: absolute;
  top: 7px !important;
  right: 40px !important;
  left:inherit;
  color: #fff;
  border-radius: 500px;
  padding: 0.05rem 0.5rem;
}
fa-layers-counter
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background: #6f6f6f;
}
.nav-pills .nav-link:hover {
  opacity:0.75;
}
.chatroom .dialogue{
  overflow-y: scroll;
  background-color: #FFFFFF;
  position: relative;
}
.post-divider {
  position: relative;
  height: 2em;
  text-align: center;
  clear: both;
}
.post-divider > hr {
  position: relative;
  top: 1em;
  z-index: 1;
  margin: 0;
}
.post-divider > span {
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 50px;
  background: #fff;
  padding: 0 1em;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 2em;
}
.chatroom .post-divider > span,
.chat-mobile .discussion .post-divider > span {
  background: #e0fbc6;
}
.chatroom hr {
  border-top: 1px solid rgba(0,0,0,.05);
}
.answer{
  width:65%;
}

.answer.left {
  padding: 0 0 0 70px !important;
  text-align: left;
  float: left;
  position: relative;
  overflow: hidden;
  clear: both;
}
.answer.right {
  padding: 0 70px 0 0 !important;
  text-align: right;
  float: right;
  position: relative;
  overflow: hidden;
  clear: both;
}
.answer .avatar img {
  width: 50px;
}
.name {
  font-weight: bold;
}
.left .avatar {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom:36px;
  left:0;
}
.right .avatar {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 36px;
  right: 0;
}
.nav-link .avatar {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.avatar img {
  display: block;
  border-radius: 500px;
  height: 100%;
}
.avatar .status, .users-img .status {
  right: 2px;
  bottom: 0;
  background: #ed4e6e;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
}
.avatar .bg-warning .fa-ban {
  padding-top: 2px;
}
.answer.left .text::before {
  left: -30px;
  border-right-color: #f0f4f8;
  border-right-width: 12px;
}
.answer.right .text::before {
  right: -30px;
  border-left-color: #c7edfc;
  border-left-width: 12px;
}
.answer .text::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  border: 18px solid transparent;
  border-bottom-width: 0;
}
.answer.left .text {
  background: #f0f4f8;
}
.answer.right .text {
  background: #c7edfc;
}
.answer .text {
  padding: 5px 10px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
  color: #333;
  border-radius: 8px 8px 0 0;
  margin-top: 5px;
  word-break: break-word;
}
.answer .time {
  padding-left: 12px;
  color: #BDBDC2;
}
.answer .time {
  font-size: 13px;
  line-height: 20px;
  position: relative;
  padding-bottom: 1px;
  margin-bottom:10px;
}
#chat-zm .btn-primary {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0095db+0,6ccec5+100 */
  background: rgb(0,149,219); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,149,219,1) 0%, rgba(108,206,197,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,149,219,1) 0%,rgba(108,206,197,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,149,219,1) 0%,rgba(108,206,197,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0095db', endColorstr='#6ccec5',GradientType=1 ); /* IE6-9 */
}

#chat-zm .btn-primary,
#chat-zm .btn-primary:focus,
#chat-zm .btn-primary:hover {
  border:0;
  box-shadow: none;
}

#chat-zm .btn{
  color:#6d7278;
  line-height: 1;
  white-space: nowrap;
}
#chat-zm .btn i {
  font-size: 16px;
  min-width: 18px;
}
#chat-zm .btn:hover {
  opacity:0.75;
}
.fa-smile-o {
  font-size: 20px;
}
/* --------SIDEBAR---------- */
#chat-zm .sidebar {
  background-color: #f0f4f8;
  padding: 0;
  overflow-x: hidden;
}
#chat-zm #sidebar-right {
  overflow-x: initial;
  height: max-content;
}
.searchbox {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #dadadc;
}
.searchbox .input-group-btn, .searchbox .ml-1{
  display: flex;

}
.searchbox .input-group-btn btn, .searchbox .ml-1 btn {
  flex: 1;
}
#chat-zm .search-icon{
  background-color: #434343;
  border: 1px solid #434343;
  box-shadow: none !important;
  color:#fff;
}
.users-img {
  position: absolute;
  top: 0;
  left: 10px;
}
.mess{
  position:absolute;
  top:0;
  left:0;
  color:#fff;
  border-radius:500px;
  padding:0.05rem 0.5rem;
  background-color: #ffc107;
}
.discussions .mess {
  position: absolute;
  top: 38px;
  right: 5px;
  color: #fff;
  border-radius: 500px;
  padding: 0;
  font-size: 11px;
  color: #fff !important;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 17px;
  left: inherit;
}
.connectes a:hover, .connectes2 a:hover {
  text-decoration:none;
}
.connectes a, .connectes2 a{
  color:inherit;
}
.connectes {
  overflow-y: scroll;
  overflow-x: hidden;
}
.connectes ul, .connectes2 ul{
  padding: 0;
}
.connectes ul li, .connectes2 ul li {
  list-style: none;
  width: 100%;
  float: left;
  padding: 8px 0;
}
.discussions .connectes ul li{
  border-bottom: 1px solid #d9dfe6;
}
.connectes ul li:hover{
  background:#fff;
}
.connectes2 ul li:hover{
  background:#f2f2f2;
}
.connectes ul li .utilisateur, .connectes2 ul li .utilisateur {
  float: left;
  width: 100%;
  position: relative;
  padding-right: 40px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  min-height: 64px;
}

.messenger .connectes ul li a {
  padding-right:inherit;
}

.users-img img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: left;
  float: fixed;
}
.fa-stack {
  height: 1.25em !important;
  line-height: 1.25em !important;
  width: 1.25em !important;
  position: absolute !important;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
}
.discussions .fa-stack, .sidebar .fa-stack, app-chat-desktop-users-connected .fa-stack {
  height: 1em !important;
  width: 1em !important;
  border:2px solid #fff;
  right: -2px;
  bottom: 5px;
}
.fa-stack:not(#hourglass) i {
  font-size: 9px;
  top: -4px;
  left: 0.5px;
}
.card.options {
  position: absolute;
  z-index: 1000;
  right: 4px;
  top: 48px;
}
.users-detail {
  padding-left: 90px;
  margin-top: 10px;
  padding-bottom: 1px;
}
.chatroom .users-detail {
  padding-left: 5px;
  margin-top: 15px;
}
.chatroom .users-detail p {
  font-size: 18px;
}
.chatroom .users-detail .icons {
  top: 23px;
}
.chatroom .users-detail a:hover {
  text-decoration: none;
}
.users-detail p {
  margin: 0;
  color: #333;
  font-weight: bold;
  line-height: 1.15;
  font-size: 17px;
}
.users-detail small span {
  color: #747474;
}
.users-detail small span a{
  color:#B8BAC3;
  text-decoration:none;
}
.users-detail .admin, .name .admin{
  color:#FE3B3B;
}
.users-detail .fem, .name .fem{
  color:#F754AA;
}
.users-detail .hom, .name .hom{
  color:#0086E1;
}
.users-detail .cpl, .name .cpl{
  color:#FB724B;
}
.users-detail .cplg, .name .cplg{
  color:#0165A9;
}
.users-detail .cpll, .name .cpll{
  color:#D84ADD;
}
.users-detail .trans, .name .trans{
  color:#C64238;
}
.icons {
  position: absolute;
  right: 0;
  top: 18px;
  width: 30px;
}
#chat-zm .icons .btn {
  padding: 0;
  background:transparent;
}
.fa.disabled,
.fa[disabled],
.disabled > .fa,
[disabled] > .fa {
  color: lightgrey;
  /*or*/ opacity: 0.5;
}
.menu-user-dial {
  z-index: 500;
  position: absolute;
  right: 0;
}
.menu-user-dial a{
  font-size:14px;
}
.time-cnx {
  position: absolute;
  right: 40px;
  top: 9px;
  font-size: 12px;
}
.discussions .time-cnx {
  position: absolute;
  right: 5px;
  top: 15px;
  font-size: 12px;
}
.sidebar small {
  margin-top: 5px;
  display: block;
}
.sidebar .active {
  background: #c7edfc;
}
.typing {
  font-size: 8px;
  opacity:0.7;
  margin-top: 3px;
  display: block;
  position: absolute;
}
.typing i {
  margin-right:2px;
}
/* --------MESSENGER---------- */
.messenger{
  width:100%;
  max-width:400px;
}
#fenetre {
  position: absolute;
  bottom: 0;
  right: 420px;
  width: 360px;
}
#fenetre2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 360px;
}
.panel{
  margin-bottom: 0;
}
.chat-window {
  bottom: 0;
  position: fixed;
  float: right;
  width: 360px;
  border: 1px solid #eaeef1;
  background: #fff;
}
.chat-window > div > .panel{
  border-radius: 5px 5px 0 0;
}
.icon_minim{
  padding:2px 10px;
}
.msg_container_base {
  background: #fff;
  padding: 0 10px 10px;
  max-height: 300px;
  overflow-x: hidden;
}
.msg_container_base2 {
  background: #fff;
  padding: 0 10px 10px 0;
  max-height: 344px;
  overflow-x: hidden;
}

.top-bar {
  background: #f2f2f2;
  padding: 10px;
  position: relative;
}
.msg_receive{
  padding-left:0;
  margin-left:0;
}
.msg_sent{
  padding-bottom:20px !important;
  margin-right:0;
}
.messages {
  background: white;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width:100%;
}
.messages > p {
  font-size: 13px;
  margin: 0 0 0.2rem 0;
}
.messages > time {
  font-size: 11px;
  color: #ccc;
}
.msg_container {
  padding: 10px;
  overflow: hidden;
  display: flex;
}
.base_receive > .avatar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid #FFF;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .avatar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(black, 0.2); // not quite perfect but close
}

.msg_sent > time{
  float: right;
}

.msg_container_base::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar-thumb
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.btn-group.dropup{
  position:fixed;
  left:0;
  bottom:0;
}

.panel-header {
  border-bottom: 1px solid #f2f2f2;
}
.panel-title {
  font-size: 14px;
  margin-top: 3px;
}
.statut {
  font-size: 10px;
}
.chat-window .name {
  font-weight: bold;
  font-size: 14px;
}
.chat-window .text {
  font-size: 14px;
}
#statut-mess {
  position: absolute;
  right: 0;
}
#statut-mess ul {
  position: absolute;
  z-index: 100;
  width: 200px;
  right: 7px;
}
#statut-mess ul a{
  color:inherit;
}
#statut-mess ul a:hover{
  text-decoration:none;
}

#statut-mess .fa-stack {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
}
.nav-chat-resp {
  display:none;
}
/* --------lightbox---------- */
.title-box {
  font-size: 1.5rem;
}
@media(max-width:1600px){
  .main-section {
    max-width: 98%;
  }
}
@media(max-width:1024px){
  .answer {
    width: 80%;
  }
}
@media(max-width:768px){
  .users-detail {
    padding-left: 60px;
    margin-top: 0;
  }
  .users-detail p {
    font-size: 14px;
  }
  .users-img img {
    width: 40px;
    height: 40px;
  }
  .connectes ul li .utilisateur, .connectes2 ul li .utilisateur {
    min-height: 40px;
  }
  .discussions .time-cnx {
    top: 2px;
  }
  .discussions .mess {
    top: 22px;
    right: 5px;
  }
  .answer {
    width: 95%;
  }
}
@media(max-width:768px){
  .users-detail {
    padding-left: 90px;
    margin-top: 10px;
  }
  .users-detail p {
    font-size: 18px;
  }
  .users-img img {
    width: 64px;
    height: 64px;
  }
  .connectes ul li .utilisateur, .connectes2 ul li .utilisateur {
    min-height: 64px;
  }
  .discussions .time-cnx {
    top: 15px;
  }
  .discussions .mess {
    top: 38px;
    right: 5px;
  }
  .nav-chat-resp {
    display:block;
  }
}
@media(max-width:414px){
  .sidebar {
    height: 67vh;
  }
}
div:empty:before {
  content:attr(data-placeholder);
  color:gray
}
.message-small {
  height: 26px;
  overflow: hidden;
}
.message-input {
  max-height: 50px;
  overflow-y: hidden;
  resize: none;
}

#chat-zm > .row:first-child {
  min-height: 300px;
}

#chat-zm .card-profile {
  border: 0;
  box-shadow: 1em 1em 2em rgba(0,0,0,.2);
  margin-bottom: 30px;
  min-height: 342px;
  border-radius: 5px;
}

.modal-content .container {
  position: relative;
}

.btn-group {
  margin-right: 4px;
}

#chat-zm .btn-group .btn {
  background-color: buttonface;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

#chat-zm input.form-control.form-control-lg.message-input {
  height: auto!important;
}

/* ------ NO ROOM -----*/

.noRoom {
  .btn-group-sm > .btn, .btn-sm {
    padding: .5rem 1.5rem;
    font-size: 1rem !important;
    line-height: 1.5;
    border-radius: .2rem;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .noRoom {
    h1, .h1 {
      font-size: 1.15rem;
      font-weight: bold;
      letter-spacing: -0.1px;
      position: relative;
    }

    p {
      font-size: 13px;
    }
  }
}
