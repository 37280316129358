.noUi-horizontal {
  height: 5px;
}

.noUi-background.noUi-vertical {
  height: 200px;
  width: 16px;
  margin: 0 auto 30px;
}

.noUi-base .noUi-connect {
  background: #e93379;
  box-shadow: inherit !important;
}

.noUi-base .noUi-handle {
  background-color: #e93379;
  border-radius: 6px;
  box-shadow: none;
  color: #FFFFFF;
  display: table;
  font-size: 12px;
  width: 34px;
  height: 28px;
  top: 1px;
  margin-top: -13px;
  position: absolute;
  text-align: center;
  z-index: 2;
  padding: 0 5px;
  border: 2px solid #ffc4db !important;
}

.noUi-base .noUi-handle::before {
  background: transparent;
  content: attr(data-value);
  display: table-cell;
  font-size: 12px;
  font-weight: 100;
  position: static;
  width: 100%;
  vertical-align: middle;
}

.noUi-horizontal .noUi-base .noUi-handle {
  cursor: ew-resize;
}

.noUi-vertical .noUi-base .noUi-handle {
  cursor: ns-resize;
  margin-left: -32px;
}

.noUi-base .noUi-handle:hover {
  background-color: #222;
}

.noUi-base .noUi-handle.noUi-active {
  background-color: #222;
  z-index: 3;
}

.noUi-base .noUi-handle::before {
  background: transparent;
  content: attr(data-value);
  display: table-cell;
  font-size: 12px;
  font-weight: 100;
  position: static;
  width: 100%;
  vertical-align: middle;
}

.noUi-base .noUi-handle::after {
  display: none;
}

.noUi-target {
  border-radius: 4px !important;
  border: 0 !important;
  box-shadow: inherit !important;
}

#connectedSlider {
  margin-top: 10px;
}

.noUi-handle.noUi-handle-lower {
  left: auto !important;
  right: -34px;
}

.noUi-handle.noUi-handle-upper {
  left: auto !important;
  right: 0 !important;
}

.search .noUi-handle.noUi-handle-lower {
  left: 40px !important;
}

.search .noUi-handle.noUi-handle-upper {
  left: 8px !important;
}

.noUi-connects {
  background: #DEE2E6 !important;
  box-shadow: inherit !important;
}
