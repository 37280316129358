/**
 * style.scss
 * Global project CSS.
 * The CSS for each module is within the corresponding component.
 * Example: pages/home-component/home-component.component.scss
 */
@import './variables';
@import './slider';
@import './common';
@import './animate';
@import './chat';
@import './chat-mobile';
