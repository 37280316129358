.chatZmMobile {

  /*
  position: absolute;
  top: 70px;
  */
  width: 100%;
  height: calc(100% - 70px);

  background-color: blue;

  .chatMobileLayer {
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    min-height: 100%;
  }

  .emojis-container {
    max-width: 60%;

    .emojis-category {
      li {
        padding: .5rem .5rem;

        i {
          font-size: 1.2rem;
        }
      }
    }

    ul {
      li {
        span {
          font-size: 1.4rem !important;
        }

        .emoji-img {
          width: 22px !important;
          height: 22px !important;
        }
      }
    }
  }

  .chat-mobile {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    padding: 0 1rem;

    .name {
      font-weight: bold;
      font-size: 17px;
    }

    .cadre {
      padding: 0;
      overflow-x: hidden;
    }

    .header {
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 9;
      top: 58px;
      padding: 1rem !important;
      background-color: #f7f7f7;
      border-bottom: 1px solid #d9d9d9;
      min-height: 51px;

      i {
        font-size: 1.15rem;
      }
    }

    h1 {
      padding-right: 0;
      font-weight: bold;
      font-size: 1.15rem;
      line-height: 1;

      .avatar {
        width: 36px;
        top: -8px;
        height: 36px;
        left: 35px;
        display: inline-block;
        position: absolute;
      }

      span {
        display: block;
        position: absolute;
        top: 0;
        width: 150px;
        left: 76px;
        margin-top: -9px;
      }

      small {
        font-size: 12px;
        margin-top: 0;
        left: 80px;
        position: absolute;
        width: 150px;
        top: 15px;
      }
      small.chat-timing {
        position: relative;
        left: 0;
        top: 0;
      }
    }

    .connectes, .discussion, .les-options {
      margin-top: 50px;
      padding-bottom: 65px;
      overflow-y: auto;
    }

    .connectes ul {
      margin: 0;
    }

    .connectes2 ul li {
      list-style: none;
    }

    .connectes ul li {
      border-bottom: 1px solid #eaeaeb;
      list-style: none;

      .utilisateur {
        float: left;
        width: 100%;
        position: relative;
        padding-right: 50px;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        min-height: 60px;
      }
    }

    .connectes a, .connectes2 a {
      color: inherit;
    }

    .users-img {
      position: absolute;
      top: 0;
      left: 15px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: left;
      }
    }

    .fa-stack {
      display: inline-block;
      vertical-align: middle;
      height: 1em !important;
      width: 1em !important;
      border: 2px solid #fff;
      right: -2px;
      bottom: 5px;
    }

    .mess {
      position: absolute;
      top: 35px;
      right: 15px;
      color: #fff;
      border-radius: 500px;
      padding: 0;
      font-size: 11px;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 17px;
      left: inherit;
    }

    .bg-primary {
      background-color: #e93379 !important;
    }

    .badge-primary {
      color: #fff;
      background-color: #e93379;
    }

    .time-cnx {
      right: 15px;
    }

    .hom {
      color: #0086E1;
    }

    .fem {
      color: #F754AA;
    }

    .answer {
      width: 75%;

      .name {
        small {
          display: inline-block;
        }
      }

      .text {
        padding: 5px 10px;
        font-size: 16px;
        line-height: 26px;
        position: relative;
        color: #333;
        border-radius: 8px 8px 0 0;
        margin-top: 5px;
        text-align: left;
      }

      &.left {
        .avatar {
          width: 50px;
          height: 50px;
          position: absolute;
          bottom: 36px;
          left: 0;
        }

        .text {
          background: #f0f4f8;
        }
      }

      &.right {
        .avatar {
          width: 50px;
          height: 50px;
          position: absolute;
          bottom: 36px;
          right: 0;
        }
        .text {
          background: #c7edfc;
        }
      }

      .time {
        font-size: 12px;
        line-height: 20px;
        position: relative;
        padding-bottom: 1px;
        margin-bottom: 10px;
        padding-left: 0;
      }
    }
  }

  .footer-mobile {
    padding: .5rem !important;
    background-color: #f7f7f7;
    border-top: 1px solid #dadadc;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 64px;
    z-index: 3;

    i {
      font-size: 1.75rem;
      position: relative;
    }

    span {
      font-size: 11px;
    }

    .badge-danger {
      color: #fff;
      background-color: #dc3545;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      line-height: 1.6;
      position: absolute;
      top: 0;
      font-size: 10px;
      font-family: arial;
      right: -10px;
    }

    .form-control {
      background-color: #fff !important;
      border: 1px solid #dadadc !important;
      box-shadow: none !important;
      border-radius: 500px !important;
      height: 38px;
      font-size: 16px;
      padding: 7px 15px;

      &:empty:before {
        content: attr(data-placeholder);
        color: gray;
      }
    }

    .btn {
      line-height: 1.6;
      color: #6d7278;

      &:hover {
        opacity: 0.75;
      }

      i {
        font-size: 16px;
        min-width: 18px;
      }
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 911px) {
  .chatZmMobile {
    .emojis-category {
      li {
        padding: .5rem 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .chatZmMobile .chat-mobile .header {
    top: 66px !important;
  }
}

@media screen and (max-width: 380px) {
  .chatZmMobile {
    .emojis-category {
      li {
        padding: .2rem .2rem !important;
      }
    }
  }
}
